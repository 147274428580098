import AccountsApi from 'api/AccountsApi';
import { useQueryHook } from 'utility/reactQueryHooks';

export const queryKeys = {
  getAllAccounts: (params) => ['getAllAccounts', JSON.stringify(params)],
  getAllCustomers: ['getAllCustomers'],
};

export const useQueryGetAllAccounts = (params) =>
  useQueryHook(queryKeys.getAllAccounts(params), () => AccountsApi.getAllAccounts(params));

export const useQueryGetAllCustomers = () =>
  useQueryHook(queryKeys.getAllCustomers, () => AccountsApi.getAllCustomers());
