import React, { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import Modal from 'components/Modal/Modal';
import SuccessCheckmark from 'components/SuccessCheckmark/SuccessCheckmark';
import { useMutationCreateSuperAdmin } from 'Customers/hooks/useCustomers';

import AddAdminForm from './AddAdminForm';

const AddAdminModal = ({ isOpen, onClose, id, users }) => {
  const queryClient = useQueryClient();
  const [error, setError] = useState(false);
  const { isLoading, mutate: CreateSuperAdmin } = useMutationCreateSuperAdmin();

  const initialValues = {};

  const handleSubmit = (data) => {
    CreateSuperAdmin(
      { id, data },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(['customersAccounts', id]);
        },
        onError: async (err) => {
          if (err.response?.data?.errors?.description?.message === 'Failed creating admin') {
            setError(true);
          }
        },
      }
    );
    setTimeout(() => {
      setError(false);
      onClose();
    }, 1000);
  };
  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      title={'Add Admin'}
      styles={{
        width: '560px',
        padding: 0,
        height: '480px',
      }}
      onRequestClose={() => {
        onClose();
      }}
    >
      {error && <SuccessCheckmark text={'Failed creating admin'} isFailed={error} hideCloseButton />}
      <AddAdminForm
        onClose={onClose}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        initialValues={initialValues}
        users={users?.data || []}
        failed={error}
      />
    </Modal>
  );
};

export default AddAdminModal;
