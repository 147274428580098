import React, { useContext } from 'react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import CustomerAccounts from 'Customers/CustomerAccounts/CustomerAccounts';
import FeatureFlags from 'FeatureFlags/FeatureFlags';
import Implementation from 'Implementation/Implementation';
import Headcount from 'Insights/Headcount/Headcount';
import Subscriptions from 'Insights/Subscriptions/Subscriptions';

import { PrivateRoutes, PublicRoutes } from './routes';
import Anchors from '../Anchors/Anchors';
import Announcements from '../Announcements/Announcements';
import { AuthContext } from '../Auth/AuthContext';
import ForgotPassword from '../Auth/ForgotPassword/ForgotPassword';
import Login from '../Auth/Login/Login';
import ResetPassword from '../Auth/ResetPassword/ResetPassword';
import Careers from '../Careers/Careers';
import Header from '../components/Header';
import Customers from '../Customers/CustomersPage/Customers';
import Dashboard from '../Dashboard/Dashboard';
import Insights from '../Insights/Insights';
import QuickPanel from '../QuickPanel/QuickPanel';
import Translations from '../Translations/Translations';
import Users from '../Users/Users';
import Accounts from 'Customers/Accounts/Accounts';

const PublicRoute = ({ component: Component }) => (
  <>
    <Component />
  </>
);

export const PrivateRoute = ({ component: Component }) => {
  const { authState } = useContext(AuthContext);
  return (
    <>
      {authState.token ? (
        <>
          <Header />
          <div style={{ marginLeft: '56px' }}>
            <Component component={Component} />
          </div>
        </>
      ) : (
        <Login />
      )}
    </>
  );
};

export const AppRouter = () => (
  <BrowserRouter>
    <Routes>
      <Route path={PrivateRoutes.dashboard} element={<PrivateRoute path='/dashboard' component={Dashboard} />} />
      <Route
        path={PrivateRoutes.translations}
        element={<PrivateRoute path='/translations' component={Translations} />}
      />
      <Route path={PrivateRoutes.customers} element={<PrivateRoute path='/customers' component={Customers} />} />
      <Route
        path={PrivateRoutes.customersAccounts}
        element={<PrivateRoute path='/customers/:customerID/accounts' component={CustomerAccounts} />}
      />
      <Route path={PrivateRoutes.accounts} element={<PrivateRoute path='/accounts' component={Accounts} />} />
      <Route
        path={PrivateRoutes.announcements}
        element={<PrivateRoute path='/announcements' component={Announcements} />}
      />
      <Route path={PrivateRoutes.anchors} element={<PrivateRoute path='/anchors' component={Anchors} />} />
      <Route path={PrivateRoutes.insights} element={<PrivateRoute component={Insights} />} />
      <Route path={PrivateRoutes.insightsSubscription} element={<PrivateRoute component={Subscriptions} />} />
      <Route path={PrivateRoutes.insightsHeadcount} element={<PrivateRoute component={Headcount} />} />
      <Route
        path={PrivateRoutes.featureFlags}
        element={<PrivateRoute path='/feature-flag' component={FeatureFlags} />}
      />
      <Route path={PrivateRoutes.careers} element={<PrivateRoute path='/careers' component={Careers} />} />
      <Route path={PrivateRoutes.quickPanel} element={<PrivateRoute path='/quick-panel' component={QuickPanel} />} />
      <Route path={PrivateRoutes.users} element={<PrivateRoute exact path='/users' component={Users} />} />
      <Route
        path={PrivateRoutes.implementation}
        element={<PrivateRoute exact path='/implementation' component={Implementation} />}
      />
      <Route path={PublicRoutes.resetPassword} element={<PublicRoute component={ResetPassword} />} />
      <Route path={PublicRoutes.forgotPassword} element={<PublicRoute component={ForgotPassword} />} />
      <Route path={PublicRoutes.login} element={<PublicRoute component={Login} />} />
      <Route path='/' element={<PublicRoute component={Login} />} />
    </Routes>
  </BrowserRouter>
);
