import React, { useContext } from 'react';

import noCustomers from 'assets/img/no_customers.png';
import { AuthContext } from 'Auth/AuthContext';
import RoundedButton from 'components/RoundedButton/RoundedButton';

const EmptyCustomersState = ({ onClick, title, desc }) => {
  const { authState } = useContext(AuthContext);
  const isAdmin = authState.user.roles.includes('ROLE_ADMIN');
  const isClientsManager = authState.user.roles.includes('ROLE_CLIENTS_MANAGER');
  return (
    <div className='radius-8 bg-light p-6 mt-5'>
      <div className='m-auto' style={{ width: '160px', height: 'auto' }}>
        <img className='w-100' src={noCustomers} alt='empty-customers' />
      </div>

      <h5 className='fw-bold mx-2 text-center text-steal'>{title}</h5>

      <p style={{ maxWidth: '264px' }} className='text-gray fs-12 text-center mx-auto'>
        {desc}
      </p>
      {(isAdmin || isClientsManager) && (
        <div className='d-flex justify-content-center mt-4'>
          <RoundedButton text='Add New' icon='icon-plus' iconPosition='left' color='primary' onClick={onClick} />
        </div>
      )}
    </div>
  );
};
export default EmptyCustomersState;
