import React from 'react';

import CustomersTableRow from '../CustomersTableRow/CustomersTableRow';
import DataTable from 'components/DataTable/DataTable';
import EmptyCustomersState from '../EmptyCustomersState/EmptyCustomersState';
import EmptyPageList from 'components/EmptyPageList/EmptyPageList';

const headerCells = [
  { title: 'Customer', sortable: true, sortValue: 'customer' },
  { title: 'Representative', sortable: true, sortValue: 'started' },
  { title: 'Total Accounts', sortable: true, sortValue: 'started' },
  { title: 'Total Subscriptions', sortable: true, sortValue: 'started' },
  { title: 'Number of Users', sortable: true, sortValue: 'last_login' },
  { title: '' },
];

const CustomersTable = ({ customers, isLoading, addNew, onSort, updateList, withSearch }) => (
  <DataTable
    isScroll
    data={customers}
    isLoading={isLoading}
    columns={headerCells}
    headerAdditionalProps={{
      onSort,
    }}
    TableRowComponent={CustomersTableRow}
    rowAdditionalProps={{ updateList }}
    EmptySearchComponent={() =>
      withSearch ? (
        <EmptyPageList title='No one matches your search' leadText='Try being less specific, or check your spelling' />
      ) : (
        <EmptyCustomersState
          onClick={addNew}
          title={'No Customers'}
          desc={'Create your first customer by clicking on the "Add New" buttons.'}
        />
      )
    }
  />
);

export default CustomersTable;
