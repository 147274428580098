const PublicRoutes = {
  login: '/login',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
};

const PrivateRoutes = {
  dashboard: 'dashboard',
  translations: 'translations',
  customers: 'customers',
  customersAccounts: 'customers/:customerID/accounts',
  accounts: 'accounts',
  announcements: 'announcements',
  anchors: 'anchors',
  insights: '/insights',
  insightsSubscription: '/insights/subscriptions',
  insightsHeadcount: '/insights/headcount',
  featureFlags: 'feature-flags',
  careers: 'careers',
  quickPanel: 'quick-panel',
  users: 'users',
  implementation: 'implementation/*',
};

export { PublicRoutes, PrivateRoutes };
