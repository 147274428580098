import React, { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import Modal from 'react-modal';

import Menu from 'components/Menu/Menu';
import modalStyles from 'components/modalStyles';
import ClientContext from 'Customers/components/detailsModal/ClientContext';
import DetailsModal from 'Customers/components/detailsModal/DetailsModal';
import StatusChip from 'Customers/components/StatusChip/StatusChip';
import DetailsCustomer from 'Customers/CustomersPage/components/DetailsCustomer/DetailsCustomer';
import {
  useMutationSuspendCustomer,
  useMutationActivateCustomer,
  useMutationArchiveCustomer,
  useMutationSyncCustomerSubscription,
  useMutationRetryFailedCustomer,
  useMutationCancelTenantSubscription,
} from 'Customers/hooks/useCustomers';
import formatCurrency from 'utility/formatCurrency';

import AddAdminModal from '../../../CustomerAccounts/AddAdminModal/AddAdminModal';
import ExtendDemoModal from '../../../CustomerAccounts/ExtendDemoModal/ExtendDemoModal';
import { getAccountType, getAccountOptions } from '../../../utility';

const addModalStyle = cloneDeep(modalStyles);
addModalStyle.content.width = '640px';
addModalStyle.content.padding = '0';

const AccountsTableRow = ({
  accountType,
  subdomain,
  fullDomain,
  status,
  id,
  users,
  trialEnd,
  customer,
  subscriptionsCount,
  createdAt,
  currency,
  representative,
  email,
  mrr,
}) => {
  const queryClient = useQueryClient();
  const account = { accountType, subdomain, status, id };
  const [detailsModal, setDetailsModal] = useState(false);
  const [detailsCustomer, setDetailsCustomer] = useState(false);
  const [addAdminModal, setAddAdminModal] = useState(false);
  const [extendDemoModal, setExtendDemoModal] = useState(false);
  const [selected, setSelected] = useState(undefined);
  const { mutate: suspendCustomer } = useMutationSuspendCustomer();
  const { mutate: archiveCustomer } = useMutationArchiveCustomer();
  const { mutate: syncSubscription } = useMutationSyncCustomerSubscription();
  const { mutate: activateCustomer } = useMutationActivateCustomer();
  const { mutate: retryFailedCustomer } = useMutationRetryFailedCustomer();
  const { mutate: cancelSubscription } = useMutationCancelTenantSubscription();

  const accountOptions = getAccountOptions(status, accountType);

  const openDetailsHandler = (item) => {
    setSelected(item);
  };

  const handleSuccess = async () => {
    await queryClient.invalidateQueries(['customersAccounts', id]);
  };

  const handleAction = (action) => {
    switch (action) {
      case 'Suspend':
        suspendCustomer(id, {
          onSuccess: () => {
            handleSuccess();
          },
        });
        break;
      case 'SyncSubscription':
        syncSubscription(id, {
          onSuccess: () => {
            handleSuccess();
          },
        });
        break;
      case 'AddAdmin':
        setAddAdminModal(true);
        break;
      case 'ExtendDemo':
        setExtendDemoModal(true);
        break;
      case 'Activate':
        activateCustomer(id, {
          onSuccess: () => {
            handleSuccess();
          },
        });
        break;
      case 'Retry':
        retryFailedCustomer(id, {
          onSuccess: () => {
            handleSuccess();
          },
        });
        break;
      case 'Archive':
        archiveCustomer(id, {
          onSuccess: () => {
            handleSuccess();
          },
        });
        break;
      case 'Delete':
        cancelSubscription(subdomain, {
          onSuccess: () => {
            handleSuccess();
          },
        });
        break;
      default:
        break;
    }
  };
  const handleShowDetails = (e) => {
    e.stopPropagation();
    setSelected(account);

    const shouldShowDetails =
      status !== 'ACTIVE' && status !== 'PENDING_SUSPENSION' && status !== 'SUSPENDED' && status !== 'CANCELLED';
    if (status !== 'PENDING') {
      setDetailsCustomer(shouldShowDetails);
      setDetailsModal(!shouldShowDetails);
    }
  };

  return (
    <ClientContext.Provider value={{ selected, openDetailsHandler }}>
      <tr onClick={handleShowDetails}>
        <td className='d-flex align-items-center py-3 px-3'>{customer}</td>
        <td>
          <div className='d-inline'>
            <p>{representative || '--'}</p>
            <p className='text-gray-500 font-size-12'>{email}</p>
          </div>
        </td>
        <td>
          <span>
            {status === 'FAILED' ? (
              <i className='icon-not-verified-fill text-red me-2' />
            ) : (
              <i className='icon-verified1-fill text-green me-2' />
            )}
            {fullDomain}
          </span>
        </td>
        <td>{getAccountType.get(accountType)}</td>
        <td className='px-5'>{subscriptionsCount}</td>
        <td>{createdAt ? moment(createdAt).format('MMM DD, YYYY') : '--'}</td>
        <td>{`${currency || ''} ${formatCurrency(mrr)}`}</td>
        {(status === 'ACTIVE' ||
          status === 'PENDING_SUSPENSION' ||
          status === 'SUSPENDED' ||
          status === 'CANCELLED') && (
          <td>
            <StatusChip status={status} />
          </td>
        )}
        <td>
          {status !== 'PENDING' && accountOptions.length > 0 && (
            <Menu data={accountOptions} onAction={(action) => handleAction(action)} />
          )}
        </td>
      </tr>
      <Modal
        isOpen={detailsModal}
        contentLabel='Add Client'
        ariaHideApp={false}
        closeTimeoutMS={300}
        style={addModalStyle}
        onRequestClose={() => {
          setDetailsModal(false);
        }}
      >
        <DetailsModal
          customer={selected || {}}
          onClose={() => {
            setDetailsModal(false);
          }}
        />
      </Modal>
      <Modal
        isOpen={detailsCustomer}
        contentLabel='Add Client'
        ariaHideApp={false}
        closeTimeoutMS={300}
        style={addModalStyle}
        onRequestClose={() => {
          setDetailsCustomer(false);
        }}
      >
        <DetailsCustomer
          customer={selected || {}}
          onClose={() => {
            setDetailsCustomer(false);
          }}
        />
      </Modal>
      <AddAdminModal
        isOpen={addAdminModal}
        id={id}
        users={users}
        onClose={() => {
          setAddAdminModal(false);
        }}
      />
      <ExtendDemoModal
        isOpen={extendDemoModal}
        trialEnd={trialEnd}
        id={id}
        onClose={() => {
          setExtendDemoModal(false);
        }}
      />
    </ClientContext.Provider>
  );
};
export default AccountsTableRow;
