import React, { useContext, useEffect, useState } from 'react';

import { PHRPagination } from '@palmhr/palmhr-ui-lib';
import _ from 'lodash';
import Modal from 'react-modal';

import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';

import InvoicePreview from './InvoicePreview';
import styles from './UnbilledCharges.module.scss';
import UnbilledChargesTable from './UnbilledChargesTable';
import CustomersApi from '../../../../api/CustomersApi';
import modalStyles from '../../../../components/modalStyles';
import ClientContext from '../ClientContext';

const addModalStyle = _.cloneDeep(modalStyles);
addModalStyle.content.width = '640px';
addModalStyle.content.padding = '20px';

const UnbilledCharges = ({ goToInvoice }) => {
  const { selected } = useContext(ClientContext);
  const [unbilledChargesPagination, setUnbilledChargesPagination] = useState([]);
  const [data, setData] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [invoicePreview, setInvoicePreview] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState('');
  const [params, setParams] = useState({
    currentPage: 1,
    perPage: 5,
  });

  useEffect(() => {
    if (selected) {
      setLoadingData(true);
      CustomersApi.getUnbilledCharges(selected.id, params)
        .then((res) => {
          setData(res.data || []);
          setUnbilledChargesPagination(res.data.pagination || []);
          setLoadingData(false);
        })
        .catch(() => {
          setLoadingData(false);
        });
    }
  }, [selected, params]);

  const updatePaginationParams = ({ currentPage, perPage }) =>
    setParams((prevState) => ({ ...prevState, currentPage, perPage }));

  const actionHandler = (id) => {
    setSelectedId(id);
    setConfirmModal(true);
  };

  const onDelete = () => {
    setIsSending(true);
    const param = selected.id;
    CustomersApi.deleteUnbilledCharge(param, selectedId)
      .then((res) => {
        let state = [...data];
        state = state.filter((el) => el.id !== selectedId);
        setData(state);
        setIsSending(false);
        setSent(true);
        setTimeout(() => {
          setSent(false);
          setConfirmModal(false);
        }, 1000);
      })
      .catch((res) => {
        setIsSending(false);
      });
  };

  const onGenerateInvoice = () => {
    setIsSending(true);
    const param = selected.id;
    CustomersApi.generateInvoice(param)
      .then(() => {
        setIsSending(false);
        setSent(true);
        setTimeout(() => {
          setSent(false);
          setInvoicePreview(false);
          goToInvoice();
        }, 1000);
      })
      .catch((err) => {
        setIsSending(false);
        if (err.response.status >= 300 && err.response.status < 500) {
          setError(err?.response?.data?.detail);
        }
      });
  };

  return (
    <div className='h-100 me-5' style={{ overflowY: 'hidden' }}>
      <div className='mt-3' style={{ height: 'calc(100vh - 265px)' }}>
        {data.length > 0 && (
          <div
            className={styles.invoiceBtn}
            onClick={() => {
              setInvoicePreview(true);
            }}
          >
            Invoice Now
          </div>
        )}
        <UnbilledChargesTable data={data} onDelete={actionHandler} isLoading={loadingData} />
      </div>

      {/* To do {unbilledChargesPagination?.total > 0 && (
        <PHRPagination onPaginationAction={updatePaginationParams} pagination={unbilledChargesPagination} />
      )} */}

      <ConfirmationModal
        isOpen={confirmModal}
        onAction={onDelete}
        onClose={() => {
          setConfirmModal(false);
        }}
        description={'Are you sure you want to delete this unbilled charge?'}
        title={'Delete Unbilled Charge'}
        type={'danger'}
        isLoading={isSending}
        actionText={'Delete'}
      />
      <Modal
        isOpen={invoicePreview}
        contentLabel='Preview'
        ariaHideApp={false}
        closeTimeoutMS={300}
        style={addModalStyle}
        onRequestClose={() => {
          setInvoicePreview(false);
        }}
      >
        <InvoicePreview
          saving={isSending}
          saved={sent}
          onGenerateInvoice={onGenerateInvoice}
          items={data}
          error={error}
          onClose={() => {
            setInvoicePreview(false);
          }}
        />
      </Modal>
    </div>
  );
};

export default UnbilledCharges;
