import * as yup from 'yup';

export const NewCustomerBasicInfoValidationSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  companyName: yup.string().required('Company name is required'),
  country: yup.string().required('Country is required'),
  workEmail: yup.string().email('Invalid email address').required('Work email is required'),
  phoneNumber: yup
    .string()
    .matches(/^\+?[0-9\s]*$/, 'Phone number must be digits only and can start with a plus sign')
    .optional()
    .nullable(),
  jobTitle: yup.string().required('Job title is required'),
  department: yup.string().optional().nullable(),
});

export const NewCustomerSetupAccountValidationSchema = yup.object({
  subdomain: yup
    .string()
    .matches(/^[a-z0-9-]+$/, 'subdomain must be in lowercase and contain only letters, numbers, and hyphens')
    .required('subdomain is required'),
  accountType: yup.string().required(),
});

export const NewCustomerSubscriptionSchema = yup.object({
  periodUnit: yup.string().required('period is required'),
  numberOfUsers: yup
    .number()
    .min(10, 'Number of users cannot be lower than 10')
    .required('Number of users is required'),
  plan: yup.string(),
  unitPrice: yup.number().required('Number of users is required'),
  discount: yup.number().optional().nullable(),
});

export const NewSetupAccountValidationSchema = yup.object({
  customerId: yup.string().required('Customer is required'),
  subdomain: yup
    .string()
    .matches(/^[a-z0-9-]+$/, 'subdomain must be in lowercase and contain only letters, numbers, and hyphens')
    .required('subdomain is required'),
  accountType: yup.string().required(),
});
