import React from 'react';

import DataTable from 'components/DataTable/DataTable';
import EmptyState from 'components/EmptyState';

import InvoiceItem from './InvoiceItem';

const headerCells = [
  { title: 'Date' },
  { title: 'Plan' },
  { title: 'Period' },
  { title: 'Status' },
  { title: 'Amount', colSpan: 2 },
];

const InvoicesTable = ({ data, handleDownload, actionHandler, isLoading }) => (
  <DataTable
    isScroll
    data={data}
    isLoading={isLoading}
    columns={headerCells}
    TableRowComponent={InvoiceItem}
    rowAdditionalProps={{ handleDownload, actionHandler }}
    EmptySearchComponent={() => <EmptyState message={'No invoices'} />}
  />
);

export default InvoicesTable;
