import React, { useState } from 'react';

import cx from 'classnames';
import CSSTransition from 'react-transition-group/CSSTransition';

import styles from './UserDataRow.module.scss';
import fadeAnimation from '../../../components/animations/fadeAnimation.module.scss';
import IconButton from '../../../components/IconButton';
import OutsideClickHandler from '../../../components/OutsideClickHandler';
import TableDataCell from '../../../components/table/TableDataCell';
import UserEditMenu from '../UserEditMenu/UserEditMenu';
import { getRoleNameById } from '../../constants';

const UserDataRow = ({
  active,
  id,
  fullName,
  username,
  permissionGroup,
  openEdit,
  openDeactivate,
  isAdmin,
  roles,
  openActivate,
}) => {
  const [menuOpened, setMenuOpened] = useState(false);
  const data = { active, id, fullName, username, permissionGroup, roles };

  return (
    <tr className={cx(styles.wrapper)}>
      <TableDataCell className='ps-5'>{id}</TableDataCell>
      <TableDataCell className='py-3'>{fullName || '--'}</TableDataCell>
      <TableDataCell className='py-3'>{username}</TableDataCell>
      <TableDataCell className='py-3'>
        <span>
          {active ? (
            <i className='icon-verified1-fill text-green me-2' />
          ) : (
            <i className='icon-not-verified-fill text-red me-2' />
          )}
          {active ? 'Active user' : 'Inactive user'}
        </span>
      </TableDataCell>
      <TableDataCell className='py-3'>
        <div className='d-flex justify-content-between me-3'>
          <div>{getRoleNameById(permissionGroup)}</div>
          {isAdmin && (
            <div className='position-relative'>
              <div className={('w-100', 'd-flex', 'align-items-center', 'justify-content-end')}>
                <IconButton
                  icon='icon-more-horizontal'
                  onClick={(e) => {
                    e.stopPropagation();
                    setMenuOpened(true);
                  }}
                />
              </div>
              <CSSTransition
                mountOnEnter
                unmountOnExit
                in={menuOpened}
                timeout={200}
                classNames={{
                  enter: fadeAnimation.enter,
                  enterActive: fadeAnimation.enterActive,
                  exit: fadeAnimation.exit,
                  exitActive: fadeAnimation.exitActive,
                }}
              >
                <OutsideClickHandler
                  clickHandler={() => {
                    setMenuOpened(false);
                  }}
                >
                  <UserEditMenu
                    onDeactivate={() => {
                      openDeactivate(data);
                      setMenuOpened(false);
                    }}
                    onEdit={() => {
                      openEdit(data);
                      setMenuOpened(false);
                    }}
                    status={active}
                    onActivate={() => {
                      openActivate(data);
                      setMenuOpened(false);
                    }}
                  />
                </OutsideClickHandler>
              </CSSTransition>
            </div>
          )}
        </div>
      </TableDataCell>
    </tr>
  );
};

export default UserDataRow;
