import React, { useState, useContext, useRef } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars-2';

import CustomerFeatureFlags from 'Customers/components/detailsModal/CustomerFeatureFlags/CustomerFeatureFlags';
import LastLoginDetails from 'Customers/components/detailsModal/LastLoginDetails/LastLoginDetails';
import TenantEventLog from 'Customers/EventLog/TenantEventLog';

import RoundedButton from 'components/RoundedButton/RoundedButton';
import BiometricDevices from './BiometricDevices/BiometricDevices';
import ClientContext from './ClientContext';
import Details from './Details/Details';
import Headcount from './Headcount/Headcount';
import { useQueryClientTasks } from './Implementation/hooks/useImplementation';
import Implementation from './Implementation/Implementation';
import Invoices from './Invoices/Invoices';
import styles from './MainContent.module.scss';
import Notes from './Notes/Notes';
import UnbilledCharges from './UnbilledCharges/UnbilledCharges';
import Subscriptions from '../../Subscriptions/Subscriptions';

const MainContent = ({ view, setView, details }) => {
  const { selected } = useContext(ClientContext);
  const isAsc = useRef(false);
  const [addDeviceModal, setAddDeviceModal] = useState(false);
  const [addNoteModal, setAddNoteModal] = useState(false);
  const [params, setParams] = useState({
    currentPage: 1,
    perPage: 10000,
    sort: '-started',
  });

  const { isLoading: isLoadingClientTasks, data: clientTasks } = useQueryClientTasks(selected.id, params);

  const onSort = (option) => {
    isAsc.current = !isAsc.current;
    const dir = isAsc.current ? '+' : '-';
    setParams((prevState) => ({ ...prevState, currentPage: 1, sort: `${dir}${option}` }));
  };

  const viewMap = {
    headcount: <Headcount />,
    details: <Details />,
    invoices: <Invoices />,
    'last login': <LastLoginDetails />,
    'unbilled charges': (
      <UnbilledCharges
        goToInvoice={() => {
          setView('invoices');
        }}
      />
    ),
    'biometric devices': (
      <BiometricDevices
        addDeviceModal={addDeviceModal}
        onEditOpen={() => {
          setAddDeviceModal(true);
        }}
        onClose={() => {
          setAddDeviceModal(false);
        }}
      />
    ),
    'feature flags': <CustomerFeatureFlags details={details} />,
    notes: (
      <Notes
        addNoteModal={addNoteModal}
        onEditOpen={() => {
          setAddNoteModal(true);
        }}
        onClose={() => {
          setAddNoteModal(false);
        }}
      />
    ),
    'event-log': (
      <div>
        <TenantEventLog data={details?.details?.eventLog ?? []} />
      </div>
    ),
    subscriptions: <Subscriptions />,
    implementation: !isLoadingClientTasks && (
      <Implementation clientTasks={clientTasks?.data.items || []} onSort={onSort} />
    ),
  };

  return (
    <div className={cx(styles.wrapper, 'pt-6 me-3 bg-white h-100 ps-5')}>
      <div className={cx(styles.title, 'd-flex justify-content-between align-items-center border-btm pb-3 me-5')}>
        {view}
        {view === 'biometric devices' && (
          <RoundedButton
            icon='icon-plus'
            text='Add New'
            color='primary'
            size='sm'
            onClick={() => {
              setAddDeviceModal(true);
            }}
          />
        )}
      </div>
      <div className={cx(styles.content)}>
        <Scrollbars className='scrollbarsWrapper h-100' autoHide autoHideTimeout={1000} autoHideDuration={200}>
          {viewMap[view]}
        </Scrollbars>
      </div>
    </div>
  );
};

MainContent.propTypes = {
  view: PropTypes.string.isRequired,
};

export default MainContent;
