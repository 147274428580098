import React from 'react';

import cx from 'classnames';
import moment from 'moment';

import styles from './UnbilledChargeItem.module.scss';
import IconButton from '../../../../components/IconButton';
import TableDataCell from '../../../../components/table/TableDataCell';
import formatCurrency from '../../../../utility/formatCurrency';

const UnbilledChargeItem = ({
  id,
  dateFrom,
  dateTo,
  description,
  currency,
  unitAmount,
  quantity,
  amount,
  onDelete,
}) => (
  <tr className={cx(styles.row, 't-row border-btm light')}>
    <TableDataCell className='ps-3'>{dateFrom ? moment(dateFrom).format('MMM DD, YYYY') : '- -'}</TableDataCell>
    <TableDataCell>{dateTo ? moment(dateTo).format('MMM DD, YYYY') : '- -'}</TableDataCell>
    <TableDataCell>{description}</TableDataCell>
    <TableDataCell>
      <div>
        <div className='text-gray small'>{currency}</div>
        <div>{unitAmount ? formatCurrency(unitAmount) : '- -'}</div>
      </div>
    </TableDataCell>
    <TableDataCell>{quantity}</TableDataCell>
    <TableDataCell>
      <div>
        <div className='text-gray small'>{currency}</div>
        <div>{amount ? `${formatCurrency(amount)}` : '- -'}</div>
      </div>
    </TableDataCell>
    <TableDataCell>
      <div
        className='d-flex justify-content-end'
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <div className='d-flex'>
          <IconButton icon='icon-trash' onClick={() => onDelete(id)} />
        </div>
      </div>
    </TableDataCell>
  </tr>
);

export default UnbilledChargeItem;
