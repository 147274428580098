import http from './Api';

class AccountsApi {
  static getAllAccounts(params) {
    return http.get(`plg/tenants/accounts`, { params });
  }

  static getAllCustomers() {
    return http.get('plg/customers/customer-sub-info');
  }
}

export default AccountsApi;
