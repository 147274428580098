import React, { useContext, useEffect, useState } from 'react';

import { PHRPagination } from '@palmhr/palmhr-ui-lib';
import _ from 'lodash';
import Modal from 'react-modal';

import InvoicesTable from './InvoicesTable';
import CustomersApi from '../../../../api/CustomersApi';
import ConfirmModal from '../../../../components/ConfirmModal/ConfirmModal';
import modalStyles from '../../../../components/modalStyles';
import ClientContext from '../ClientContext';

const addModalStyle = _.cloneDeep(modalStyles);
addModalStyle.content.width = '640px';
addModalStyle.content.marginTop = '100px';

const Invoices = () => {
  const { selected } = useContext(ClientContext);
  const [data, setData] = useState([]);
  const [invoicesPagination, setInvoicesPagination] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [actionType, setActionType] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [loadingData, setLoadingData] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [params, setParams] = useState({
    currentPage: 1,
    perPage: 10,
  });

  useEffect(() => {
    if (selected) {
      setLoadingData(true);
      CustomersApi.getCustomerInvoices(selected.id, params)
        .then((res) => {
          setData(res.data.items || []);
          setInvoicesPagination(res.data.pagination || []);
          setLoadingData(false);
        })
        .catch(() => {
          setLoadingData(false);
        });
    }
  }, [selected, params]);

  const updatePaginationParams = ({ currentPage, perPage }) =>
    setParams((prevState) => ({ ...prevState, currentPage, perPage }));

  const onDownload = (invoice, action) => {
    const param = selected.id;
    CustomersApi.downloadInvoice(param, invoice, action)
      .then((response) => {
        if (action === 'preview') {
          window.open(response.data?.download?.download_url);
        } else {
          const link = document.createElement('a');
          link.href = response.data?.download?.download_url;
          link.setAttribute('download', 'invoice.pdf');
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(() => {});
  };

  const onDelete = () => {
    setIsSending(true);
    const param = selected.id;
    CustomersApi.deleteInvoice(param, selectedId)
      .then(() => {
        let state = [...data];
        state = state.filter((el) => el.id !== selectedId);
        setData(state);
        setIsSending(false);
        setSent(true);
        setTimeout(() => {
          setSent(false);
          setConfirmModal(false);
        }, 1000);
      })
      .catch(() => {
        setIsSending(false);
      });
  };

  const onMarkAsPaid = () => {
    setIsSending(true);
    const param = selected.id;
    CustomersApi.markAsPaid(param, selectedId)
      .then(() => {
        const state = [...data];
        const index = _.findIndex(state, ['id', selectedId]);
        state[index].status = 'paid';
        setData(state);
        setIsSending(false);
        setSent(true);
        setTimeout(() => {
          setSent(false);
          setConfirmModal(false);
        }, 1000);
      })
      .catch(() => {
        setIsSending(false);
      });
  };

  const actionHandler = (action, id) => {
    setActionType(action);
    setSelectedId(id);
    setConfirmModal(true);
  };

  return (
    <div className='h-100 d-flex justify-content-start flex-column gap-2 me-5' style={{ overflowY: 'hidden' }}>
      <div className='mt-3' style={{ height: 'calc(100vh - 265px)' }}>
        <InvoicesTable isLoading={loadingData} data={data} actionHandler={actionHandler} handleDownload={onDownload} />
      </div>
      {invoicesPagination?.total > 0 && (
        <PHRPagination onPaginationAction={updatePaginationParams} pagination={invoicesPagination} />
      )}

      <Modal
        isOpen={confirmModal}
        contentLabel='Add Client'
        ariaHideApp={false}
        closeTimeoutMS={300}
        style={addModalStyle}
        onRequestClose={() => {
          setConfirmModal(false);
        }}
      >
        <ConfirmModal
          isSending={isSending}
          sent={sent}
          onClose={() => {
            setConfirmModal(false);
          }}
          onSubmit={actionType === 'paid' ? onMarkAsPaid : onDelete}
          modalName={actionType === 'paid' ? 'Mark invoice paid' : 'Delete Invoice'}
          confirmName={actionType === 'paid' ? 'Confirm' : 'Delete'}
          confirmText={
            actionType === 'paid'
              ? 'Are you sure you want to mark this as paid?'
              : 'Are you sure you want to delete this invoice?'
          }
        />
      </Modal>
    </div>
  );
};

export default Invoices;
