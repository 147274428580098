import React, { useEffect, useRef, useState } from 'react';

import cx from 'classnames';
import { useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';

import { queryKeys, useQueryGetAllAccounts } from './hooks/useAccounts';
import { useQueryGetAdminUsers } from 'Users/hooks/useUsers';
import { PHRPagination } from '@palmhr/palmhr-ui-lib';
import useDebounce from 'Shared/useDebounce';
import Search from 'components/Search';
import styles from './Accounts.module.scss';
import RoundedButton from 'components/RoundedButton/RoundedButton';
import IconButton from 'components/IconButton';
import AccountsTable from './components/AccountsTable/AccountsTable';
import AddNewAccountModal from './components/AddNewAccountModal/AddNewAccountModal';
import NavTab from 'components/NavTab/NavTab';
import SearchFilterMenu from 'components/searchFilterMenu/SearchFilterMenu';
import SideTransitionContainer from 'components/searchFilterMenu/SideTransitionContainer';

const Accounts = () => {
  const queryClient = useQueryClient();
  const isAsc = useRef(false);

  const [search, setSearch] = useState('');
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [status, setStatus] = useState('ACTIVE');
  const [filterMenu, setFilterMenu] = useState(false);
  const [filterMapArray, setFilterMapArray] = useState([
    {
      title: 'Account Type',
      options: [
        { id: 1, field: 'accountType', label: 'Live Site', selected: false },
        { id: 2, field: 'accountType', label: 'Test Site', selected: false },
        { id: 3, field: 'accountType', label: 'Demo', selected: false },
        { id: 4, field: 'accountType', label: 'Trial', selected: false },
      ],
    },
  ]);
  const [selectedFilter, setSelectedFilter] = useState([]);

  const [params, setParams] = useState({
    accountStatus: status,
    currentPage: 1,
    perPage: 10,
    search: '',
    sort: '',
  });

  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    setParams((prevState) => ({ ...prevState, currentPage: 1, search: debouncedSearch }));
  }, [debouncedSearch]);

  useEffect(() => {
    setParams((prevState) => ({ ...prevState, currentPage: 1, accountStatus: status }));
  }, [status]);

  const { isLoading: isLoadingCustomers, data } = useQueryGetAllAccounts(params);

  const { data: users } = useQueryGetAdminUsers({ currentPage: 1, perPage: 9999 });

  const customersData = data?.data?.items || [];

  const showSearch =
    isLoadingCustomers ||
    (!search && customersData.length > 0) ||
    (search && customersData.length === 0) ||
    (search && customersData.length > 0);

  const onSort = (option) => {
    isAsc.current = !isAsc.current;
    const dir = isAsc.current ? '+' : '-';
    setParams((prevState) => ({ ...prevState, currentPage: 1, sort: `${dir}${option}` }));
  };

  const updateList = () => {
    queryClient.invalidateQueries(queryKeys.getAllAccounts(params));
  };

  const updatePaginationParams = ({ currentPage, perPage }) => {
    setParams((prevState) => ({ ...prevState, currentPage, perPage }));
  };

  const handleView = (text) => {
    setStatus(text);
  };
  const OpenModal = () => {
    setAddCustomerModal(true);
  };

  const closeFilterMenu = () => {
    setFilterMenu(false);
  };

  const openFilterMenu = () => {
    setFilterMenu(true);
  };

  const onSelectField = (value, field, id, groupTitle, label) => {
    setSelectedFilter((prevFilters) => {
      const updatedFilters = _.cloneDeep(prevFilters);
      const filterIndex = updatedFilters.findIndex((f) => f.id === id);

      if (filterIndex >= 0) {
        updatedFilters[filterIndex].selected = value;
      } else {
        updatedFilters.push({ field, id, label, selected: value, value: [_.toLower(id)] });
      }

      return updatedFilters;
    });

    const stateArray = [...filterMapArray];
    stateArray.every((el) => {
      if (el.title === groupTitle) {
        el.options.every((elem) => {
          if (`${elem.id}` === `${id}`) {
            elem.selected = value;
            return false;
          }
          return true;
        });
      }
      return true;
    });
    setFilterMapArray(stateArray);
  };

  const onApplyFilter = (filters) => {
    setFilterMenu(false);

    const accountTypeFilters = filters
      .filter((f) => f.field === 'accountType' && f.selected)
      .map((f) => {
        if (f.label.toLowerCase() === 'live site') {
          return 'LIVE_INSTANCE';
        }
        return f.label.toUpperCase().replace(' ', '_');
      });

    const updatedParams = { ...params };

    if (accountTypeFilters.length > 0) {
      updatedParams.accountType = accountTypeFilters.join(',');
    } else {
      delete updatedParams.accountType;
    }

    setParams(updatedParams);

    // Clear ALL
    if (filters.length === 0) {
      const stateArray = [...filterMapArray];
      stateArray.forEach((el) => {
        el.options.forEach((elem) => {
          elem.selected = false;
        });
      });

      setFilterMapArray(stateArray);
      setSelectedFilter([]);
    }
  };

  return (
    <div className='mt-3 mx-3'>
      <div className={cx(styles.wrapper, 'panel p-0')}>
        <div className='px-5 py-4 border-btm d-flex align-items-center justify-content-between'>
          <div>
            <h2>Accounts</h2>
            <p className='tab-subtitle'>List of all accounts and their details</p>
          </div>
          <div className='d-flex align-items-center justify-content-center '>
            <NavTab text='ACTIVE' onClick={handleView} active={status === 'ACTIVE'} />
            <NavTab text='PENDING' onClick={handleView} active={status === 'PENDING'} />
            <NavTab text='FAILED' onClick={handleView} active={status === 'FAILED'} />
            <NavTab text='ARCHIVED' onClick={handleView} active={status === 'ARCHIVED'} />
          </div>
          <RoundedButton icon='icon-plus' text='Add New' color='primary' size='sm' onClick={OpenModal} />
        </div>

        <div className='d-flex mb-2 mt-2 flex-row align-items-center justify-content-between px-5'>
          <div className='col-4 p-2'>
            <Search
              className='p-0'
              onChange={(e) => {
                setSearch(e.target.value.replace(/^\s+/g, ''));
              }}
              placeholder='Search customer'
              value={search}
              disabled={!showSearch}
              onClear={() => {
                setSearch('');
              }}
            />
          </div>
          <div>
            <SideTransitionContainer isIn={filterMenu} zIndex={105} onClose={closeFilterMenu}>
              <SearchFilterMenu
                filterMapArray={filterMapArray}
                selectedFilter={selectedFilter}
                onApplyFilter={onApplyFilter}
                onSelectField={onSelectField}
                onClose={closeFilterMenu}
              />
            </SideTransitionContainer>
            <IconButton icon='icon-filter' onClick={openFilterMenu} size='md' />
          </div>
        </div>
        <div style={{ height: 'calc(100vh - 190px)' }} className='mx-5'>
          <div className='h-100 d-flex justify-content-start flex-column gap-2'>
            <AccountsTable
              onSort={onSort}
              isLoading={isLoadingCustomers}
              customers={customersData}
              updateList={updateList}
              addNew={OpenModal}
              withSearch={Boolean(search)}
              view={status}
              users={users}
            />
            {data?.data?.pagination?.total > 0 && (
              <PHRPagination onPaginationAction={updatePaginationParams} pagination={data?.data?.pagination} />
            )}
          </div>
        </div>
      </div>
      <AddNewAccountModal
        isOpen={addCustomerModal}
        onClose={() => {
          setAddCustomerModal(false);
        }}
      />
    </div>
  );
};

export default Accounts;
