import React, { useState, useEffect, useContext } from 'react';

import { PHRPagination } from '@palmhr/palmhr-ui-lib';

import Search from 'components/Search';
import ClientContext from 'Customers/components/detailsModal/ClientContext';
import useDebounce from 'Shared/useDebounce';

import TenantSubscriptionsTable from './components/TenantSubscriptionsTable/TenantSubscriptionsTable';
import { useQueryGetTenantSubscriptions } from './hooks/useSubscriptions';

const Subscriptions = () => {
  const { selected } = useContext(ClientContext);
  const [search, setSearch] = useState('');
  const debouncedSearchTerm = useDebounce(search, 500);
  const [params, setParams] = useState({
    status: '',
    currentPage: 1,
    perPage: 10,
    search: '',
    sort: '',
  });

  useEffect(() => {
    setParams((prevState) => ({ ...prevState, currentPage: 1, search: debouncedSearchTerm }));
  }, [debouncedSearchTerm]);

  const updatePaginationParams = ({ currentPage, perPage }) => {
    setParams((prevState) => ({ ...prevState, currentPage, perPage }));
  };

  const { isLoading, data: TenantSubscriptions } = useQueryGetTenantSubscriptions(selected.id, params);

  return (
    <div className='h-100 d-flex justify-content-start flex-column gap-2 me-5' style={{ overflowY: 'hidden' }}>
      <div className='col-4 py-3'>
        <Search
          className='p-0'
          onChange={(e) => {
            setSearch(e.target.value.replace(/^\s+/g, ''));
          }}
          placeholder='Search by plan'
          value={search}
          onClear={() => {
            setSearch('');
          }}
        />
      </div>
      <div style={{ height: 'calc(100vh - 265px)' }}>
        <TenantSubscriptionsTable subscriptions={TenantSubscriptions?.data?.items} isLoading={isLoading} />
      </div>
      {TenantSubscriptions?.data?.pagination?.total > 0 && (
        <PHRPagination onPaginationAction={updatePaginationParams} pagination={TenantSubscriptions?.data?.pagination} />
      )}
    </div>
  );
};

export default Subscriptions;
