import React from 'react';

import DataTable from 'components/DataTable/DataTable';
import CustomerAccountsTableRow from '../CustomerAccountsTableRow/CustomerAccountsTableRow';
import EmptyState from 'components/EmptyState';

const headerCells = [
  { title: 'Account Type', sortable: true, sortValue: 'accountType' },
  { title: 'Subdomain', sortable: true, sortValue: 'subdomain' },
  { title: 'account status', sortable: true, sortValue: 'accountStatus' },
  { title: '' },
];

const CustomerAccountsTable = ({ accounts, isLoading, onSort, users }) => (
  <DataTable
    isScroll
    data={accounts}
    isLoading={isLoading}
    columns={headerCells}
    headerAdditionalProps={{
      onSort,
    }}
    rowAdditionalProps={{ users }}
    TableRowComponent={CustomerAccountsTableRow}
    EmptySearchComponent={() => <EmptyState brandImage='no_customers.png' title='No Accounts' showBtn={false} />}
  />
);

export default CustomerAccountsTable;
