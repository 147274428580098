import React, { useContext, useEffect, useState } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import Scrollbars from 'react-custom-scrollbars-2';

import Modal from 'components/Modal/Modal';
import AddDevice from './AddDevice';
import AddNote from './AddNote';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import BiometricDetails from './BiometricDetails';
import styles from './BiometricDevices.module.scss';
import BiometricTableRow from './BiometricTableRow';
import BiometricDveicesApi from '../../../../api/BiometricDevicesApi';
import Search from '../../../../components/Search';
import TableSkeleton from '../../../../components/skeletons/TableSkeleton';
import TableHead from '../../../../components/table/TableHead';
import ClientContext from '../ClientContext';

const headerCells = [
  { title: 'Name', sortable: true, sortValue: 'name' },
  { title: 'Serial Number', sortable: true, sortValue: 'serialNumber' },
  { title: 'Protocol' },
  { title: 'Last sync' },
  { title: 'Configured at', colSpan: 2 },
];

const BiometricDevices = ({ addDeviceModal, onClose, onEditOpen }) => {
  const { selected } = useContext(ClientContext);
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(undefined);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [detailsModal, setDetailsModal] = useState(false);
  const [addNoteModal, setAddNoteModal] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedNote, setSelectedNote] = useState(undefined);
  const [UnbilledModal, setUnbilledModal] = useState(false);

  const param = selected.id;

  const getAllDevices = () => {
    if (selected) {
      setLoading(true);
      BiometricDveicesApi.getDevices(param)
        .then((res) => {
          setDevices(res.data.items || []);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getAllDevices();
  }, [selected]);

  useEffect(() => {
    if (searchValue) {
      let state = [...devices];
      state = state.filter((device) => {
        const checkName = device.name.toLowerCase().includes(searchValue.toLowerCase());
        const checkSerialNumber = device.serialNumber.toLowerCase().includes(searchValue.toLowerCase());
        return checkName || checkSerialNumber;
      });
      setFilteredDevices(state);
    } else {
      setFilteredDevices(devices);
    }
  }, [searchValue, devices]);

  const onSort = (option, isAsc) => {
    const dir = isAsc ? 'asc' : 'desc';
    let state = [...devices];
    state = _.orderBy(state, [option], [dir]);
    setFilteredDevices(state);
  };

  const closeModalHandler = () => {
    onClose(false);
    setSelectedDevice(null);
  };

  const selectDevice = (device) => {
    setSelectedDevice(device);
    setDetailsModal(true);
  };

  const onEdit = (id) => {
    BiometricDveicesApi.getDeviceById(id).then((res) => {
      setSelectedDevice(res.data);
      onEditOpen(true);
    });
  };

  const onSave = (isEdit, data) => {
    setSaving(true);
    if (isEdit) {
      BiometricDveicesApi.updateDevice(selectedDevice.id, data)
        .then(() => {
          setSaving(false);
          setSaved(true);

          setTimeout(() => {
            setSaved(false);
            closeModalHandler(false);
            setSelectedDevice(null);

            getAllDevices();
          }, 1000);
        })
        .catch((err) => {
          setSaving(false);

          if (err?.response?.data?.message) {
            setErrorMessage(err.response.data.message);
          } else if (err?.response?.data?.detail) {
            setErrorMessage(err.response.data.detail);
          } else {
            setErrorMessage('Error! Something went wrong!');
          }
        });
    } else {
      BiometricDveicesApi.createDevice(param, data)
        .then(() => {
          setSaving(false);
          setSaved(true);

          setTimeout(() => {
            setSaved(false);
            closeModalHandler(false);
            setSelectedDevice(null);

            getAllDevices();
          }, 1000);
        })
        .catch((err) => {
          setSaving(false);
          let msg = '';
          if (err?.response?.data?.message) {
            msg = err.response.data.message;
          } else if (err?.response?.data?.detail) {
            msg = err.response.data.detail;
          } else {
            msg = 'Error! Something went wrong!';
          }

          if (msg.trim() === 'Bad Request') {
            msg = 'Device already exists';
          }

          setErrorMessage(msg);
        });
    }
  };

  const onDelete = (id) => {
    setLoading(true);
    BiometricDveicesApi.deleteDevice(id)
      .then(() => {
        let state = [...devices];
        state = state.filter((el) => el.id !== id);
        setDevices(state);

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onMakeBillable = (id, currentIsAddedToUnbilled) => {
    setLoading(true);

    const data = {
      isAddedToUnbilledCharges: !currentIsAddedToUnbilled,
    };

    BiometricDveicesApi.updateDevice(id, data)
      .then(() => {
        setSaving(false);
        setSaved(true);

        setTimeout(() => {
          setSaved(false);
          closeModalHandler(false);
          setSelectedDevice(null);

          getAllDevices();
        }, 1000);
      })
      .catch((err) => {
        setSaving(false);

        if (err?.response?.data?.message) {
          setErrorMessage(err.response.data.message);
        } else if (err?.response?.data?.detail) {
          setErrorMessage(err.response.data.detail);
        } else {
          setErrorMessage('Error! Something went wrong!');
        }
      });
  };

  const onAddNote = (id) => {
    BiometricDveicesApi.getDeviceById(id).then((res) => {
      setSelectedDevice(res.data);

      setAddNoteModal(true);
      setDetailsModal(false);
    });
  };

  const onEditNote = (id) => {
    BiometricDveicesApi.getNote(selectedDevice?.id, id).then((res) => {
      setSelectedNote(res.data);
      setAddNoteModal(true);
      setDetailsModal(false);
    });
  };

  const onSaveNote = (isEditNote, data) => {
    setSaving(true);
    if (isEditNote) {
      BiometricDveicesApi.editNote(selectedDevice.id, selectedNote.id, data)
        .then(() => {
          setSaving(false);
          setSaved(true);
          setTimeout(() => {
            setSaved(false);
            setAddNoteModal(false);
            setDetailsModal(true);
            setSelectedNote(null);
          }, 2000);
        })
        .catch((err) => {
          setSaving(false);
          let msg = '';
          if (err?.response?.data?.message) {
            msg = err.response.data.message;
          } else if (err?.response?.data?.detail) {
            msg = err.response.data.detail;
          } else {
            msg = 'Error! Something went wrong!';
          }

          if (msg.trim() === 'Bad Request') {
            msg = 'Error! Something went wrong!';
          }

          setErrorMessage(msg);
        });
    } else {
      BiometricDveicesApi.createNote(selectedDevice?.id, data)
        .then(() => {
          setSaving(false);
          setSaved(true);

          setTimeout(() => {
            setSaved(false);
            setAddNoteModal(false);
            setDetailsModal(true);
            setSelectedDevice(null);
            setSelectedNote(null);

            getAllDevices();
          }, 1000);
        })
        .catch((err) => {
          setSaving(false);
          let msg = '';
          if (err?.response?.data?.message) {
            msg = err.response.data.message;
          } else if (err?.response?.data?.detail) {
            msg = err.response.data.detail;
          } else {
            msg = 'Error! Something went wrong!';
          }

          if (msg.trim() === 'Bad Request') {
            msg = 'Error! Something went wrong!';
          }

          setErrorMessage(msg);
        });
    }
  };

  const count = devices.filter((item) => item.isAddedToUnbilledCharges === false).length;

  const onConfirm = () => {
    setSaving(true);
    BiometricDveicesApi.addToUnbilled(param)
      .then(() => {
        setUnbilledModal(false);
        setSaving(false);
        setSaved(true);

        setTimeout(() => {
          setSaved(false);
          setUnbilledModal(false);
        }, 1000);
      })
      .catch((err) => {
        setSaving(false);
        let msg = '';
        if (err?.response?.data?.message) {
          msg = err.response.data.message;
        } else if (err?.response?.data?.detail) {
          msg = err.response.data.detail;
        } else {
          msg = 'Error! Something went wrong!';
        }

        if (msg.trim() === 'Bad Request') {
          msg = 'Error! Something went wrong!';
        }

        setErrorMessage(msg);
      });
  };

  const closeNoteModalHandler = () => {
    setAddNoteModal(false);
    setSelectedDevice(null);
    setSelectedNote(null);
  };

  return (
    <div className='h-100' style={{ overflowY: 'hidden' }}>
      <div className='d-flex justify-content-between my-3 me-5'>
        <div className='d-flex'>
          {!searchValue && filteredDevices.length === 0 ? (
            ''
          ) : (
            <Search
              onChange={(e) => {
                setSearchValue(e.target.value.replace(/^\s+/g, ''));
              }}
              placeholder='Search device'
              value={searchValue}
              onClear={() => {
                setSearchValue('');
              }}
            />
          )}
        </div>
        <div className='d-flex flex-end'>
          {count > 0 && (
            <button
              type='button'
              onClick={() => {
                setUnbilledModal(true);
              }}
              className={styles.btnDevices}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <i className='icon-plus me-2' />
                <span> Add To Unbilled Charges</span>
              </div>
            </button>
          )}
        </div>
      </div>
      {filteredDevices.length === 0 && !loading ? (
        <div className='pt-8 m-auto'>
          <div className={cx(styles.emptyList, 'm-auto')}>
            <div className={cx(styles.iconHistory, 'd-flex', 'justify-content-center', 'align-items-center', 'm-auto')}>
              <i className={cx(styles.icon, 'icon-pos')} />
            </div>
            <p className='mt-4 text-center text-capitalize'>No devices</p>
          </div>
        </div>
      ) : (
        <Scrollbars
          className='scroll-bar'
          autoHide
          renderView={({ style, ...props }) => <div {...props} style={{ ...style, overflowX: 'hidden' }} />}
          style={{ height: 'calc(100vh - 275px)' }}
        >
          <div className='pe-5 pb-7 mb-7'>
            <table className='w-100 client-list'>
              <TableHead headerCells={headerCells} onSort={onSort} className={styles.fixedHeader} />
              <tbody>
                {filteredDevices.length > 0 &&
                  !loading &&
                  filteredDevices.map((item) => (
                    <BiometricTableRow
                      key={item.id}
                      data={item}
                      onEdit={onEdit}
                      onEditOpen={addDeviceModal}
                      onMakeBillable={onMakeBillable}
                      onDelete={onDelete}
                      onAddNote={onAddNote}
                      onSelect={() => {
                        setDetailsModal(true);
                        selectDevice(item);
                      }}
                    />
                  ))}
                {loading && (
                  <tr className='w-100 client-list'>
                    <td colSpan='6'>
                      <TableSkeleton lineHight={45} mb='mb-2' num={5} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Scrollbars>
      )}
      <Modal
        isOpen={addDeviceModal}
        shouldCloseOnOverlayClick
        title={selectedDevice ? 'Edit Device' : 'Add Device'}
        styles={{
          width: '560px',
          padding: 0,
        }}
        onRequestClose={() => {
          closeModalHandler(false);
        }}
      >
        <AddDevice
          onClose={closeModalHandler}
          tenant={selected?.id}
          selectedDevice={selectedDevice}
          onSave={onSave}
          saving={saving}
          saved={saved}
          errorMessageOnSave={errorMessage}
        />
      </Modal>
      <Modal
        isOpen={detailsModal}
        shouldCloseOnOverlayClick
        title={selectedDevice?.name}
        styles={{
          width: '650px',
          padding: 0,
        }}
        onRequestClose={() => {
          setDetailsModal(false);
        }}
      >
        <BiometricDetails
          id={selectedDevice?.id}
          tenant={param}
          onEditNote={onEditNote}
          onAddNote={onAddNote}
          onClose={() => {
            setDetailsModal(false);
            setSelectedDevice(null);
          }}
        />
      </Modal>
      <Modal
        isOpen={addNoteModal}
        shouldCloseOnOverlayClick
        title={selectedNote ? 'Edit note' : `Add Note for device: ${selectedDevice?.name}`}
        styles={{
          width: '640px',
          padding: 0,
        }}
        onRequestClose={() => {
          closeNoteModalHandler(false);
          setSelectedNote(null);
        }}
      >
        <AddNote
          onClose={closeNoteModalHandler}
          selectedDevice={selectedDevice}
          onSaveNote={onSaveNote}
          selectedNote={selectedNote}
          saving={saving}
          saved={saved}
          errorMessageOnSave={errorMessage}
        />
      </Modal>
      <ConfirmationModal
        isOpen={UnbilledModal}
        isLoading={saving}
        onAction={onConfirm}
        onClose={() => {
          setUnbilledModal(false);
        }}
        description={`Number of unbilled devices: ${count}, Do 
        you want to add all unbilled devices to unbilled charges?`}
        title={'Add to unbilled charges'}
        type={'info'}
        actionText={'Confirm'}
      />
    </div>
  );
};

export default BiometricDevices;
