import React from 'react';

import DataTable from 'components/DataTable/DataTable';
import EmptyPageList from 'components/EmptyPageList/EmptyPageList';
import AccountsTableRow from '../AccountsTableRow/AccountsTableRow';
import EmptyCustomersState from 'Customers/CustomersPage/components/EmptyCustomersState/EmptyCustomersState';

const AccountsTable = ({ customers, isLoading, addNew, onSort, updateList, withSearch, view, users }) => {
  const headerCells = [
    { title: 'Customer', sortable: true, sortValue: 'customer' },
    { title: 'Representative', sortable: true, sortValue: 'representative' },
    { title: 'Subdomain', sortable: true, sortValue: 'domain' },
    { title: 'type', sortable: true, sortValue: 'accountType' },
    { title: 'Subscriptions', sortable: true, sortValue: 'subscriptionsCount' },
    { title: 'Date created', sortable: true, sortValue: 'createdDate' },
    { title: 'MRR', sortable: true, sortValue: 'mrr' },
    view === 'ACTIVE' && { title: 'Status', sortable: true, sortValue: 'status' },
    { title: '' },
  ];
  return (
    <DataTable
      isScroll
      data={customers}
      isLoading={isLoading}
      columns={headerCells}
      headerAdditionalProps={{
        onSort,
      }}
      TableRowComponent={AccountsTableRow}
      rowAdditionalProps={{ updateList, users }}
      EmptySearchComponent={() =>
        withSearch ? (
          <EmptyPageList
            title='No one matches your search'
            leadText='Try being less specific, or check your spelling'
          />
        ) : (
          <EmptyCustomersState
            onClick={addNew}
            title={'No Accounts'}
            desc={'Create your first account by clicking on the "Add New" buttons.'}
          />
        )
      }
    />
  );
};

export default AccountsTable;
