import React, { useState } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import CSSTransition from 'react-transition-group/CSSTransition';

import { PACKAGE_NAMES } from 'Customers/constants';

import InvoiceEditMenu from './InvoiceEditMenu';
import styles from './InvoiceItem.module.scss';
import fadeAnimation from '../../../../components/animations/fadeAnimation.module.scss';
import IconButton from '../../../../components/IconButton';
import OutsideClickHandler from '../../../../components/OutsideClickHandler';
import TableDataCell from '../../../../components/table/TableDataCell';
import formatCurrency from '../../../../utility/formatCurrency';

const InvoiceItem = ({ id, created, plan, period, status, amount, currency, handleDownload, actionHandler }) => {
  const [editOpened, setEditOpened] = useState(false);

  const onDownload = () => {
    handleDownload(id, 'download');
  };
  const onPreview = () => {
    handleDownload(id, 'preview');
  };
  const onDelete = () => {
    actionHandler('delete', id);
  };
  const onMarkAsPaid = () => {
    actionHandler('paid', id);
  };
  return (
    <tr className={cx(styles.row, 't-row border-btm light')} onClick={onPreview}>
      <TableDataCell className='ps-3'>{created ? moment(created).format('MMM DD, YYYY') : '- -'}</TableDataCell>
      <TableDataCell>{PACKAGE_NAMES[plan] || plan}</TableDataCell>
      <TableDataCell>{period}</TableDataCell>
      <TableDataCell>
        <div className={cx(styles.status, styles[_.camelCase(status)], 'text-center')}>{_.lowerCase(status)}</div>
      </TableDataCell>
      <TableDataCell>{amount ? `${currency} ${formatCurrency(amount)}` : '- -'}</TableDataCell>
      <TableDataCell>
        <div
          className='d-flex justify-content-end'
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <div className='d-flex'>
            <IconButton icon='icon-eye me-3' onClick={onPreview} />
            {status === 'paid' ? (
              <IconButton icon='icon-download' onClick={onDownload} />
            ) : (
              <div className='position-relative'>
                <IconButton
                  icon='icon-more-vertical'
                  onClick={() => {
                    setEditOpened(true);
                  }}
                />
                <CSSTransition
                  mountOnEnter
                  unmountOnExit
                  in={editOpened}
                  timeout={200}
                  classNames={{
                    enter: fadeAnimation.enter,
                    enterActive: fadeAnimation.enterActive,
                    exit: fadeAnimation.exit,
                    exitActive: fadeAnimation.exitActive,
                  }}
                >
                  <OutsideClickHandler
                    clickHandler={() => {
                      setEditOpened(false);
                    }}
                  >
                    <InvoiceEditMenu
                      onPaid={() => {
                        onMarkAsPaid();
                        setEditOpened();
                      }}
                      onDownload={() => {
                        onDownload();
                        setEditOpened();
                      }}
                      onDelete={() => {
                        onDelete();
                        setEditOpened();
                      }}
                    />
                  </OutsideClickHandler>
                </CSSTransition>
              </div>
            )}
          </div>
        </div>
      </TableDataCell>
    </tr>
  );
};

export default InvoiceItem;
