import {
  NewCustomerSubscriptionSchema,
  NewSetupAccountValidationSchema,
} from 'Customers/validations/NewCustomerValidationSchema';
import CustomerAddSubscription from '../CustomersPage/components/CustomerAddSubscription/CustomerAddSubscription';
import SetupAccount from './components/SetupAccount/SetupAccount';

export const initializeAccountValues = () => ({
  country: '',
  customerId: '',
  subdomain: '',
  accountType: 'LIVE_INSTANCE',
  periodUnit: '',
  numberOfUsers: '',
  plan: '',
  unitPrice: '',
  unitPriceAfter: null,
  discount: '',
  includeFullCompensation: '',
  addonSubscriptions: [],
});

export const newAccountWizardSteps = (initialValues) => {
  if (initialValues.accountType === 'LIVE_INSTANCE') {
    return [
      {
        id: 1,
        component: SetupAccount,
        validationSchema: NewSetupAccountValidationSchema,
        isValid: NewSetupAccountValidationSchema.isValidSync(initialValues),
      },
      {
        id: 2,
        component: CustomerAddSubscription,
        validationSchema: NewCustomerSubscriptionSchema,
        isValid: NewCustomerSubscriptionSchema.isValidSync(initialValues),
      },
    ];
  }
  return [
    {
      id: 1,
      component: SetupAccount,
      validationSchema: NewSetupAccountValidationSchema,
      isValid: NewSetupAccountValidationSchema.isValidSync(initialValues),
    },
  ];
};
