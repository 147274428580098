import React from 'react';
import { PHRPagination } from '@palmhr/palmhr-ui-lib';
import Scrollbars from 'react-custom-scrollbars';

import CardsSkeleton from 'components/skeletons/CardsSkeleton';
import CustomerCard from '../CustomerCard/CustomerCard';
import EmptyPageList from 'components/EmptyPageList/EmptyPageList';
import EmptyCustomersState from '../EmptyCustomersState/EmptyCustomersState';
import styles from './CustomersCards.module.scss';

const CustomersCards = ({ customersData, updatePaginationParams, pagination, isLoading, addNew, withSearch }) => (
  <>
    {isLoading && <CardsSkeleton col={3} height={289} num={4} />}
    {customersData.length === 0 && !isLoading && (
      <>
        {withSearch ? (
          <EmptyPageList
            title='No one matches your search'
            leadText='Try being less specific, or check your spelling'
          />
        ) : (
          <EmptyCustomersState
            onClick={addNew}
            title={'No Customers'}
            desc={'Create your first customer by clicking on the "Add New" buttons.'}
          />
        )}
      </>
    )}

    {customersData.length > 0 && !isLoading && (
      <div className='h-100 d-flex justify-content-start flex-column gap-2'>
        <Scrollbars
          className='scrollbarsWrapper'
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          style={{ height: `100%` }}
        >
          <div className={styles.grid}>
            {customersData.length > 0 && customersData.map((customer) => <CustomerCard data={customer} />)}
          </div>
        </Scrollbars>
        <PHRPagination onPaginationAction={updatePaginationParams} pagination={pagination} />
      </div>
    )}
  </>
);

export default CustomersCards;
