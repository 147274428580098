import React from 'react';

import InputsSectionContainer from 'Customers/CustomersPage/components/InputsSectionContainer/InputsSectionContainer';
import CustomerSubdomainInput from 'Customers/CustomersPage/components/CustomerSetupAccount/CustomerSubdomainInput';
import CustomerAccountTypeInput from 'Customers/CustomersPage/components/CustomerSetupAccount/CustomerAccountTypeInput';
import SelectCustomerInput from '../SelectCustomerInput/SelectCustomerInput';
import { useQueryGetAllCustomers } from 'Customers/Accounts/hooks/useAccounts';

const SetupAccount = (formik) => {
  const { isLoading, data } = useQueryGetAllCustomers();
  const customerList = data?.data || [];
  return (
    <section className='w-100'>
      <h4 className='subtitle font-size-20 mt-7 mb-5'>Let&apos;s setup the account</h4>
      <InputsSectionContainer
        className='my-2'
        title='Select Customer'
        subTitle={'Choose with which customer would you like to link this account to.'}
      >
        <SelectCustomerInput formik={formik} customerList={customerList} isLoading={isLoading} />
      </InputsSectionContainer>
      <InputsSectionContainer
        className='my-2'
        title='Choose your subdomain'
        subTitle={
          'Enter your subdomain name with max 30 characters.\n' +
          'Allowed: lowercase letters, numbers or hyphen\n' +
          'System will create workspace: your_subdomain.palm.hr'
        }
      >
        <CustomerSubdomainInput formik={formik} />
      </InputsSectionContainer>
      <InputsSectionContainer
        className={`my-2 ${formik.errors.accountType && formik.touched.accountType ? 'border-danger' : ''}`}
        title='Account Type'
        subTitle={'Select the type of account for this customer'}
      >
        <CustomerAccountTypeInput formik={formik} />
      </InputsSectionContainer>
    </section>
  );
};
export default SetupAccount;
