import React from 'react';

import SelectField from 'components/SelectField';

const SelectCustomerInput = ({ customerList, isLoading, formik }) => {
  const handleSelect = (name, selectedValue) => {
    formik.setFieldValue(name, selectedValue.value);
    formik.setFieldValue('country', selectedValue.data.country);
  };
  return (
    <SelectField
      isCustom
      label='Customer'
      name='customerId'
      placeholder='Select'
      options={customerList}
      value={formik.values.customerId ?? ''}
      onChange={handleSelect}
      onBlur={formik.onBlur}
      error={formik.touched.customerId && formik.errors.customerId}
      useTranslate
      isRequired
      isFormik={false}
      isDisabled={isLoading}
    />
  );
};

export default SelectCustomerInput;
