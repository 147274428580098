import React, { useState } from 'react';
import cx from 'classnames';
import { useFormik } from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { useQueryClient } from '@tanstack/react-query';

import RoundedButton from 'components/RoundedButton/RoundedButton';
import SelectField from 'components/SelectField';
import TextInput from 'components/forms/TextInput/TextInput';
import { queryKeys, useUpdateUser, useMutationAddUser } from './hooks/useUsers';
import { userRoleOptions } from './constants';

const AddUser = ({ onClose, selectedUser, params }) => {
  const queryClient = useQueryClient();
  const [isSending, setIsSending] = useState(false);
  const { mutate: updateMutate } = useUpdateUser();
  const { mutate: addMutate } = useMutationAddUser();

  const handleEditSuccess = async () => {
    await queryClient.invalidateQueries(queryKeys.users(params), { exact: true });
    setIsSending(false);
    onClose();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullName: selectedUser ? selectedUser.fullName : '',
      username: selectedUser ? selectedUser.username : '',
      permissionGroup: selectedUser ? selectedUser.permissionGroup : '',
    },
    validationSchema: yup.object({
      fullName: yup.string().required('Full name is required'),
      username: yup.string().email('Invalid email address').required('Email is required'),
      permissionGroup: yup.string().required('Role is required'),
    }),
    onSubmit: (values) => {
      setIsSending(true);
      const { username, ...data } = values;
      if (selectedUser) {
        updateMutate(
          { id: selectedUser.id, data },
          {
            onSuccess: async () => {
              handleEditSuccess();
            },
          }
        );
      } else {
        addMutate(values, {
          onSuccess: async () => {
            handleEditSuccess();
          },
          onError: async (err) => {
            setIsSending(false);
            if (err.response?.data?.errors?.description?.message === 'Username already exists')
              formik.setFieldError('username', 'Username already exists');
          },
        });
      }
    },
  });

  const closeHandler = () => {
    if (isSending) {
      return;
    }
    onClose();
    formik.resetForm();
  };
  // --- render view addUser ---//
  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <div className='m-5'>
        <div className='row'>
          <div className='col-md-12 mb-1'>
            <TextInput
              name='fullName'
              label='Full Name'
              placeholder='John Doe'
              value={formik.values.fullName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isRequired
              error={formik.touched.fullName && formik.errors.fullName}
            />
          </div>
          <div className='col-md-12 mb-1'>
            <TextInput
              name='username'
              label='Email'
              placeholder='john@doe.com'
              value={formik.values.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isDisabled={selectedUser}
              isRequired
              error={formik.touched.username && formik.errors.username}
            />
          </div>
          <div className='col-md-12 mb-9'>
            <SelectField
              isCustom
              label='User role'
              name='permissionGroup'
              placeholder='Select'
              options={userRoleOptions}
              value={formik.values.permissionGroup}
              onChange={formik.handleChange}
              error={formik.touched.permissionGroup && formik.errors.permissionGroup}
              isRequired
            />
          </div>
        </div>
      </div>
      <div className={cx('d-flex', 'justify-content-end gap-2', 'p-3', 'border-t')}>
        <RoundedButton text='cancel' btnStyle='outlined' size='md' onClick={closeHandler} disabled={isSending} />
        <RoundedButton loading={isSending} type='submit' text='Save' btnStyle='contained' color='primary' size='md' />
      </div>
    </form>
  );
};
AddUser.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default AddUser;
