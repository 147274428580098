import React from 'react';

import UserDataRow from '../UserDataRow/UserDataRow';
import DataTable from 'components/DataTable/DataTable';
import EmptyState from 'components/EmptyState/EmptyState';

const headerCells = [
  { title: 'EMPLOYEE NO.' },
  { title: 'FULL NAME' },
  { title: 'EMAIL' },
  { title: 'STATUS' },
  { title: 'ROLE' },
  { title: ' ' },
];
const UsersTable = ({ users, isLoading, openEdit, openDeactivate, openActivate, isAdmin }) => (
  <DataTable
    isScroll
    paginationDivCss='mt-auto'
    data={users}
    isLoading={isLoading}
    columns={headerCells}
    TableRowComponent={UserDataRow}
    rowAdditionalProps={{ openEdit, openDeactivate, openActivate, isAdmin }}
    EmptySearchComponent={() => <EmptyState message={'No users'} />}
  />
);

export default UsersTable;
