import React from 'react';

import DataTable from 'components/DataTable/DataTable';
import EmptyState from 'components/EmptyState';

import UnbilledChargeItem from './UnbilledChargeItem';

const headerCells = [
  { title: 'From' },
  { title: 'To' },
  { title: 'Description' },
  { title: 'Unit Amount' },
  { title: 'Quantity' },
  { title: 'Amount', colSpan: 2 },
];

const UnbilledChargesTable = ({ data, isLoading, onDelete }) => (
  <DataTable
    isScroll
    data={data}
    isLoading={isLoading}
    columns={headerCells}
    TableRowComponent={UnbilledChargeItem}
    rowAdditionalProps={{ onDelete }}
    EmptySearchComponent={() => <EmptyState message={'No unbilled charges'} />}
  />
);

export default UnbilledChargesTable;
