import React, { useContext } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { AuthContext } from '../Auth/AuthContext';
import NavHeader from './NavHeader';
import NavItem from './NavItem';
import styles from './SideBar.module.scss';
import { useNavigate } from 'react-router';
import PlatformMenu from './PlatformMenu';

const SideBar = ({ close, show, open, currentView, setCurrentView }) => {
  const { authState, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  const isAdmin = authState.user.roles.includes('ROLE_ADMIN');
  const isClientsManager = authState.user.roles.includes('ROLE_CLIENTS_MANAGER');

  const logout = () => {
    dispatch({
      type: 'del',
    });
    navigate('/login');
  };

  const handleOnClick = (path) => {
    navigate(`/${path}`);
  };
  return (
    <div className={cx(styles.wrapper, { [styles.minimized]: show }, 'fixed-top')}>
      <NavHeader open={open} close={close} minimized={show} />
      <div className={styles.navSection}>
        <div className={styles.navMain}>
          <NavItem
            onClick={() => {
              setCurrentView('dashboard');
              handleOnClick('dashboard');
            }}
            icon='icon-tasks-2'
            title='Dashboard'
            active={currentView === 'dashboard'}
            isMinimized={!!show}
            tooltip='Dashboard'
          />

          {(isAdmin || isClientsManager) && (
            <NavItem
              onClick={() => {
                handleOnClick('customers');
                setCurrentView('customers');
              }}
              icon='icon-users'
              title='Customers'
              active={currentView === 'customers'}
              isMinimized={!!show}
              tooltip='Customers'
            />
          )}
          {(isAdmin || isClientsManager) && (
            <NavItem
              onClick={() => {
                handleOnClick('accounts');
                setCurrentView('accounts');
              }}
              icon='icon-user-add'
              title='Accounts'
              active={currentView === 'accounts'}
              isMinimized={!!show}
              tooltip='Accounts'
            />
          )}

          <PlatformMenu
            onClick={handleOnClick}
            isMinimized={!!show}
            isAdmin={isAdmin}
            tooltip='Platform'
            currentView={currentView}
          />
          <NavItem
            onClick={() => {
              handleOnClick('users');
              setCurrentView('users');
            }}
            icon='icon-user-circle'
            title='Users'
            active={currentView === 'users'}
            isMinimized={!!show}
            tooltip='Users'
          />
          <NavItem
            onClick={() => {
              handleOnClick('insights');
              setCurrentView('insights');
            }}
            icon='icon-custom-3'
            title='Insights'
            active={currentView === 'insights'}
            isMinimized={!!show}
            tooltip='Insights'
          />
          <NavItem
            onClick={() => {
              handleOnClick('careers');
              setCurrentView('careers');
            }}
            icon='icon-case'
            title='Careers'
            active={currentView === 'careers'}
            isMinimized={!!show}
            tooltip='Careers'
          />
          <NavItem
            onClick={() => {
              handleOnClick('implementation');
              setCurrentView('implementation');
            }}
            icon='icon-settings'
            title='Implementation'
            active={currentView === 'implementation'}
            isMinimized={!!show}
            tooltip='Implementation'
          />
        </div>
        <div className={cx(styles.navFooter, 'mb-12')}>
          <NavItem
            onClick={logout}
            icon='icon-exit'
            title='Sign Out'
            active={''}
            isMinimized={!!show}
            tooltip='Sign Out'
          />
          <NavItem
            icon='icon-user'
            title={authState.user.fullName ? authState.user.fullName : authState.user.username}
            active={''}
            isMinimized={!!show}
            tooltip={authState.user.fullName ? authState.user.fullName : authState.user.username}
          />
        </div>
      </div>
    </div>
  );
};

SideBar.propTypes = {
  close: PropTypes.func.isRequired,
  show: PropTypes.string.isRequired,
  open: PropTypes.func.isRequired,
  currentView: PropTypes.string.isRequired,
};

export default SideBar;
