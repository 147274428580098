import React from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { useMutationAddNewAccount } from 'Customers/hooks/useCustomers';
import useResolveHandler from 'Shared/useResolveHandler';

import WizardModal from '../../../Modal/WizardModal/WizardModal';
import { initializeAccountValues, newAccountWizardSteps } from '../../utility';

const AddNewAccountModal = ({ isOpen, onClose }) => {
  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutationAddNewAccount();
  const initialValues = initializeAccountValues();
  const { responseStatus, setStatus } = useResolveHandler();

  const prepareSubscriptionData = (submitData) => ({
    plan: 'plg-palmhr-core',
    numberOfUsers: submitData.numberOfUsers,
    unitPrice: submitData.unitPrice,
    type: 'SUBSCRIPTION',
    country: submitData.country,
    periodUnit: submitData.periodUnit,
    addonSubscriptions: submitData?.addonSubscriptions?.map((item) => ({
      ...item,
      country: submitData.country,
      periodUnit: submitData.periodUnit,
    })),
  });

  const onSubmit = (submitData, formikHelpers) => {
    const id = submitData.customerId;
    const data = {
      subdomain: submitData.subdomain,
      accountType: submitData.accountType,
      subscription: submitData.accountType === 'LIVE_INSTANCE' ? prepareSubscriptionData(submitData) : {},
    };

    mutate(
      { id, data },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(['allAccounts']);
          formikHelpers.resetForm();
          setStatus(true, onClose);
        },
      }
    );
  };

  return (
    <WizardModal
      onClose={onClose}
      isOpen={isOpen}
      initialValues={initialValues}
      onSubmit={onSubmit}
      title='New Account'
      isLoading={isLoading}
      generateWizardStepsWithValidation={newAccountWizardSteps}
      responseStatus={responseStatus}
      submitButtonText='Add'
      updateKey='accountType'
    />
  );
};

export default AddNewAccountModal;
